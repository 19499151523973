<template>
  <sdCards>
    <template v-slot:title>
      <div class="setting-card-title">
        <sdHeading as="h4">Profile Details</sdHeading>
        <span>Set Up Your Personal Information</span>
      </div>
    </template>
    <BasicFormWrapper>
      <a-form
        layout="horizontal"
        :model="formState"
        :rules="rules"
        @submit="onPersonalDetailsSubmit"
      >
        <a-row :gutter="25">
          <a-col :xl="12" :lg="12" :xs="24">
            <a-form-Item label="First Name">
              <a-input
                required
                name="first_name"
                placeholder="Enter first name"
                v-model:value="formState.first_name"
              />
            </a-form-Item>
            <a-form-Item label="Last Name">
              <a-input
                required
                placeholder="Enter last name"
                v-model:value="formState.last_name"
              />
            </a-form-Item>
          </a-col>
          <a-col :xl="12" :lg="12" :xs="24">
            <a-form-item label="Email">
              <a-input
                disabled
                type="email"
                placeholder="Enter email"
                v-model:value="formState.email"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div class="setting-card-title">
          <sdHeading as="h4">Company Details</sdHeading>
          <span>Set Up Your Company Information</span>
        </div>
        <a-row :gutter="25">
          <a-col :xl="12" :lg="12" :xs="24">
            <a-form-item label="Company Name">
              <a-input
                required
                placeholder="Enter company name"
                v-model:value="formState.company_name"
              />
            </a-form-item>
            <a-form-item label="Company No.">
              <a-input
                required
                placeholder="Enter company no."
                v-model:value="formState.company_no"
              />
            </a-form-item>
            <a-form-item label="Support Email">
              <a-input
                required
                type="email"
                placeholder="Enter support email"
                v-model:value="formState.support_email"
              />
            </a-form-item>
            <a-form-item label="Website">
              <a-input
                required
                type="url"
                placeholder="Enter website url"
                v-model:value="formState.website"
              />
            </a-form-item>
          </a-col>
          <a-col :xl="12" :lg="12" :xs="24">
            <a-form-item label="Address">
              <a-input
                required
                placeholder="Enter address"
                v-model:value="formState.address"
              />
            </a-form-item>
            <a-form-item label="City">
              <a-input
                required
                placeholder="Enter city"
                v-model:value="formState.city"
              />
            </a-form-item>
            <a-form-item label="Zipcode">
              <a-input
                required
                type="number"
                placeholder="Enter zipcode"
                v-model:value="formState.zip_code"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div class="setting-card-title">
          <sdHeading as="h4">Password Details</sdHeading>
          <span>Set Up Your Security Information</span>
        </div>
        <a-row :gutter="25">
          <a-col :xl="12" :lg="12" :xs="24">
            <a-form-item label="Password">
              <a-input
                type="password"
                placeholder="Enter password"
                v-model:value="formState.password"
              />
            </a-form-item>
          </a-col>
          <a-col :xl="12" :lg="12" :xs="24">
            <a-form-item label="Confirm Password">
              <a-input
                :required="formState.password.length > 0"
                placeholder="Enter confirm password"
                v-model:value="confirm_password"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div class="setting-form-actions text-center">
          <sdButton size="default" htmlType="submit" type="primary">
            Update
          </sdButton>
        </div>
      </a-form>
    </BasicFormWrapper>
  </sdCards>
</template>
<script>
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import { BasicFormWrapper } from "../../../styled";
import Notif from "@/services/Notification";

const Profile = defineComponent({
  name: "Profile",
  components: { BasicFormWrapper },
  data() {
    return {
      formState: {
        first_name: "",
        last_name: "",
        company_name: "",
        company_no: "",
        address: "",
        zip_code: "",
        city: "",
        website: "",
        email: "",
        support_email: "",
        password: "",
      },
      rules: {},
      confirm_password: "",
    };
  },
  computed: {
    user: function () {
      return this.$store.getters.authUser;
    },
  },
  beforeMount() {
    const {
      first_name = "",
      last_name = "",
      company_name = "",
      company_no = "",
      address = "",
      zip_code = "",
      city = "",
      website = "",
      email = "",
      support_email = "",
    } = this.user;
    this.formState = {
      ...this.formState,
      first_name,
      last_name,
      company_name,
      company_no,
      address,
      zip_code,
      city,
      website,
      support_email,
      email,
    };
  },
  methods: {
    ...mapActions(["updateProfile"]),
    handleCancel(e) {
      e.preventDefault();
    },
    async onPersonalDetailsSubmit(e) {
      e.preventDefault();
      if (this.formState.password != this.confirm_password) {
        Notif.show("Please enter valid password", {
          type: "info",
          title: "Password issue!",
        });
        return null;
      }
      try {
        await this.updateProfile(this.formState);
      } catch (error) {
        if (error?.message) {
          Notif.show(error?.message, { type: "error", title: "Error" });
        }
      }
    },
  },
  created() {
    const rules = {};
    for (const [key] of Object.entries(this.formState)) {
      rules[key] = [
        {
          required: true,
          message: `Please enter ${key.replace("_", " ")}`,
          trigger: "blur",
        },
      ];
    }
    this.rules = rules;
  },
});

export default Profile;
</script>
